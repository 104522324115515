// Card.jsx
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import iconNegocios from './../../../../assets/home-broker-dealer-icon.svg';
import iconKey from './../../../../assets/components/icons/keyb.png';
import Modal from './../../components/modals/cofreclaimagic.jsx';
import { linkApp } from "./../../../generals/configuracion/variablesPublicas.jsx";

import './Cards.css'

function Card(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [cofresmProcesando, setCofresmProcesando] = useState({});
  const { t } = useTranslation();





const onClose = () => {
        setIsOpen(!isOpen);
  };

// function handleClaiMagic(reclamo, cofresmAReclamar) {
//   let username=localStorage.getItem("user");

//   if (cofresmAReclamar >= reclamo) {
//     const requestOptions = {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({ user_cofres: { user_claim: username, cofres: reclamo }})
//     };
//     fetch(`./chest_claimagic?user_cofres`, requestOptions)
//       .then(response => response.json())
//       .then(data => {console.log(data)
//       if(data.error){
//         alert(data.error);
//       }else{
//       setCofresmProcesando(data.body.cofres_m_obtenidos);
//         setIsOpen(!isOpen);}
//         })
//       .catch(error => console.log(error));
//   } else {
//    alert(`${t("cofres.AlertaNoTienes")} ${reclamo} ${t("cofres.cofres")} `);
//   }
// }




function handleClaimMagic(reclamo, cofresmAReclamar) {
  const username = localStorage.getItem("user");

  if (!username) {
    alert(t("error.noUser")); // Mensaje si no hay usuario
    return;
  }

  if (cofresmAReclamar >= reclamo) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user_cofres: { user_claim: username, cofres: reclamo } })
    };

    fetch(`${linkApp}/chest_claimagic?user_cofres`, requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          alert(data.error);
        } else {
          setCofresmProcesando(data.body.cofres_m_obtenidos);
          setIsOpen(true); // Cambiar a true para abrir el modal
        }
      })
      .catch(error => {
        console.error(error);
        alert(t("error.fetchError")); // Mensaje genérico para errores de red
      });
  } else {
    alert(`${t("cofres.AlertaNoTienes")} ${reclamo} ${t("cofres.cofres")}`);
  }
}


  return (
    <>
       <div className="CardCofresMagicos">
    <div className="box-nft">
      <div className="content">
      <h5>  Magic Chest <a href={props.link} style={{ background: 'gold', borderRadius: '5px', color: 'black', padding: '5px', display: 'inline-block', float: 'right' }} title="More Info" target="_blank">?</a></h5> 
      
      <h2>{props.title}</h2>
        <img src={props.img} alt="Horses" className="cajaCofresMagicos" />
        <p>{props.description}</p>
      
    
  <button type="submit">
  <img src={iconNegocios} alt={props.name} className="icono" style={{width: "25px", height: "25px"}}   onClick={() => props.handlebuyBHRT(props.item_name, props.amount_bhrt)
  }/>
</button>

 <button type="submit">
<img src={iconKey} alt={props.name} className="icono" style={{width: "25px", height: "25px"}}  onClick={() => props.handlebuyKeyMagic(props.item_name, props.amount_bhr)
  }/> 
</button>
 <button type="submit">
<img src="image/cofres/comun.jpg" alt={props.name} className="icono" style={{width: "25px", height: "25px"}} onClick={() => handleClaimMagic(props.reclamo,props.cofresareclamar)
  }/> 


</button>
               


 
      </div>

    </div>
      
    </div>
    <Modal isOpen={isOpen} onClose={onClose} cofresProcesando={cofresmProcesando} /> 
      <Modal isOpen={isOpen} onClose={onClose} cofresProcesando={cofresmProcesando} /> 
    </>  
  );
}

export default Card;
