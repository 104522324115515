import React from 'react';

const Minado = ({ t, walletData, logos, handleRetiro }) => {
  return (
    <td>  
      <span>{t('wallet.minado')}<br/><h5>{walletData} <img src={logos} alt="Token" className="coin" /></h5></span>
      <button onClick={() => handleRetiro(walletData)}><h1>📤</h1></button>
    </td>
  );
};

export default Minado;
