// walletData.js
import { linkApp } from './../../../../../../../generals/configuracion/variablesPublicas.jsx';

export const fetchWalletData = async (user, setWalletData, setSaldo, setGanancia, setGananciaConsuelo, setGananciaConsueloBHR, setTickets, setStatusRetiro) => {
  try {
    const response = await fetch(`${linkApp}/wallet/?usuario=${user}`);
    const data = await response.json();

    const balanceDrops = data.body[0].balance_drops || 0;
    const retirosDrops = data.body[0].retiros_drops || 0;
    const result = (balanceDrops - retirosDrops).toFixed(2);
    setWalletData(result);

    const balance = data.body[0].balance || 0;
    const retiros = data.body[0].retiros || 0;
    const saldo = (balance - retiros).toFixed(2);
    const tickets = (data.body[0].tickets * 100).toFixed(2) || 0;

    const ganancia_a = (data.body[0].ganancia_apuestas || 0).toFixed(2);
    const status_retiro = data.body[0].status_retiro;

    const ganancia_b = (
      (data.body[0].ganancias_apuestas_segundoLugar || 0) +
      (data.body[0].ganancias_apuestas_tercerLugar || 0) +
      (data.body[0].ganancias_apuestas_quintoLugar || 0) +
      (data.body[0].ganancias_apuestas_sextoLugar || 0)
    ).toFixed(2);

    const ganancia_BHR = (data.body[0].ganancias_apuestas_cuartoLugar || 0).toFixed(3);

    setStatusRetiro(status_retiro);
    setSaldo(saldo);
    setGanancia(ganancia_a);
    setGananciaConsuelo(ganancia_b);
    setGananciaConsueloBHR(ganancia_BHR);
    setTickets(tickets);

  } catch (error) {
    console.error("Error al obtener datos de la billetera:", error);
  }
};
