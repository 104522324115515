import React from 'react';
import hivelogo from '../../../../../../../../assets/logoblockhorseracing/logoHive.png';

const BalanceAndProfit = ({
  t,
  tickets,
  statusRetiro,
  saldo,
  ganancia,
  setSeleccion,
  handleDepositarSaldo,
  seleccionDepositarVisible,
  seleccionRetirarVisible,
  setSeleccionRetirarVisible,
  setSeleccionDepositarVisible,
  handleDepositarVista,
  handleRetirarVista,
  manejarDeposito,
  handleRetiro,
  handleRetiroSaldo,
  handleClaimGanancia,
}) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div className="walletBalanceTabla" style={{ flex: '1' }}>
        <td>
          {/* Mostrar tickets si hay */}
        

          {/* Mostrar balance */}
          <span>
            
            Balance {statusRetiro === 'pending' ? '⏳' : statusRetiro === 'whitdrawing' ? '🚦' : ''}
            <br />
            <h5>

              {saldo} <img src={hivelogo} alt="Token" className="coin" />

            </h5>

            {seleccionDepositarVisible && (
              <td>
                <tr>
                  <select onChange={(e) => setSeleccion(e.target.value)}>
                    <option value="0.5">0.5 Swap.Hive</option>
                    <option value="1">1 Swap.Hive</option>
                    <option value="2">2 Swap.Hive</option>
                    <option value="3">3 Swap.Hive</option>
                    <option value="5">5 Swap.Hive</option>
                  </select>
                </tr>
                <button onClick={handleDepositarSaldo}>
                  <span>{t('wallet.depositar')}</span>
                </button>
              </td>
            )}
            {seleccionRetirarVisible && (
              <span>
                <button onClick={() => handleRetiroSaldo(saldo)}> 
                  <span>{t('wallet.retirar')}</span>
                </button>   
              </span>
            )}
            <button onClick={() => { handleDepositarVista(seleccionDepositarVisible, setSeleccionDepositarVisible, setSeleccionRetirarVisible); }}>
              <h1>📥</h1>
            </button>
            <button onClick={() => { handleRetirarVista(seleccionRetirarVisible, setSeleccionDepositarVisible, setSeleccionRetirarVisible); }}>
              <h1>📤</h1>
            </button>

          </span>


          {/* Mostrar ganancias */}
          <span style={{ marginTop: '1em' }}> {/* Add some space above profits */}
            {t('wallet.profit')}<br />
            <h5>{ganancia} <img src={hivelogo} alt="Token" className="coin" /></h5>
            <button onClick={() => handleClaimGanancia(ganancia)}>
              <span>{t('wallet.claim')}</span>
            </button>
          </span>
  {tickets > 0 && (
            <span>
              Tickets: <h5>🎟️{tickets}</h5>
            </span>
          )}
        </td>
      </div>
    </div>
  ); 
};

export default BalanceAndProfit;
