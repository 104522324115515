import React from 'react';
import ModalWalletBHRT from './../modals/modalWalletProfitsBHRT';

const GananciaConsuelo = ({ 
  t, 
  gananciaConsuelo, 
  logos, 
  openModalInf, 
  isOpenInf, 
  closeModalInf, 
  setIsOpenInf 
}) => {
  

  return (
    <td>
      <span>{t('wallet.profit')}<br/><h5> {gananciaConsuelo}<img src={logos} alt="Token" className="coin" /></h5></span>
      <button onClick={() => openModalInf(gananciaConsuelo)}><span>{t('wallet.claim')}</span></button>
      {isOpenInf && (  
        <ModalWalletBHRT 
          isOpen={isOpenInf} 
          closeModal={closeModalInf}   
          propsi={gananciaConsuelo}  
          onClose={() => setIsOpenInf(false)}
        >
          <button className="modal-close" onClick={closeModalInf}>X</button>
        </ModalWalletBHRT> 
      )}
    </td>
  );
};

export default GananciaConsuelo;
