import axios from 'axios';
import { linkApp } from './../../../../../../../generals/configuracion/variablesPublicas.jsx';












export const handleDepositarVista =  (seleccionDepositarVisible, setSeleccionDepositarVisible, setSeleccionRetirarVisible) => {
    if (!seleccionDepositarVisible) {
      // Si el select no es visible, lo hacemos visible
      setSeleccionDepositarVisible(true);
      setSeleccionRetirarVisible(false);
    } else {
      // Si el select ya es visible, ejecutamos la función handleDepositarSaldo
      setSeleccionDepositarVisible(false);
      // handleDepositarSaldo(seleccion);
    }
  };


export const handleRetirarVista = (seleccionRetirarVisible, setSeleccionDepositarVisible, setSeleccionRetirarVisible) => {
  if (!seleccionRetirarVisible) {
    setSeleccionDepositarVisible(false);
    setSeleccionRetirarVisible(true);
  } else {
    setSeleccionRetirarVisible(false);
  }
};


// const handleDepositarSaldo = () => {
//   manejarDeposito(seleccion);
// };


export const manejarDeposito = async (seleccion,saldo) => {

  console.log("SALDO SEL",seleccion)
let memo=`Add Balance BHR EQ`
let toUsername="equino"
let username=localStorage.getItem("user");
let amount="" + Number(seleccion).toFixed(3)
//let amount="1.000" 


if(saldo<=15){

const total_prox = Number(seleccion) + Number(saldo);
console.log("TOTOTAL",total_prox)
const limiteMaximo = 15;

if (total_prox > limiteMaximo) {
  amount = limiteMaximo - saldo;
  amount = "" + amount.toFixed(3)
}
if (window.hive_keychain) {
  const keychain = window.hive_keychain;
  keychain.requestSendToken(username, toUsername, amount, memo, 'SWAP.HIVE', (response) => {
    console.log("RES KeyC",response);
    alert(response.message)
    axios.post(`${linkApp}/deposit_wallet`, response) 
      .then(res => {
        //console.log("axio response",response)
       
     })
      .catch(error => {
        //console.log("error DE post axio",error)
        // manejar cualquier error que ocurra durante la llamada a la API
      });
  });
} else {
  alert('You do not have hive keychain installed');
}
}else{
  alert('alcanzaste el saldo maximo permitido en wallets', 5)
}


};































export const handleRetiro = async (data, amounte) => {
  const username = localStorage.getItem("user");
  const consulta = { usuario: username, balance_drops: Number(data), status_claim: "create" };


  // Enviar la transacción firmada al servidor utilizando Axios
  axios.post(`${linkApp}/claim_drop`, consulta)
    .then(res => {
      // Mostrar la respuesta en una alerta emergente
      alert(`Respuesta del servidor: ${JSON.stringify(res.data.body)}`);
    })
    .catch(error => {
      //console.log("error DE post axios", error);
      // Manejar cualquier error que ocurra durante la llamada a la API
    });
};

export const handleRetiroSaldo = async (data, statusRetiro) => {
  const username = localStorage.getItem("user");
  const consulta = { usuario: username, balance: Number(data)};


if(Number(data) > 0.5){  

if (statusRetiro !== "pending" && statusRetiro !== "whitdrawing"){
  // Enviar la transacción firmada al servidor utilizando Axios
  axios.post(`${linkApp}/claim_balance`, consulta)
    .then(res => {
      // Mostrar la respuesta en una alerta emergente
      alert(`Respuesta del servidor: ${JSON.stringify(res.data.body)}`);
    })
    .catch(error => {
      //console.log("error DE post axios", error);
      // Manejar cualquier error que ocurra durante la llamada a la API
    });

}else{ alert("You must wait for the withdrawal to be fully processed") }
}else{alert("The minimum withdrawal allowed is 0.500 Swap.Hive")};


}








export const handleClaimGanancia = async (data) => {
  const username = localStorage.getItem("user");
  const requerimiento = { usuario: username, ganancia_apuestas: Number(data)};

  // Enviar la transacción firmada al servidor utilizando Axios
  axios.post(`${linkApp}/claim_ganancia`, requerimiento)
    .then(res => {
      // Mostrar la respuesta en una alerta emergente
      alert(`Respuesta del servidor: ${JSON.stringify(res.data.body)}`);
    })
    .catch(error => {
      //console.log("error DE post axios", error);
      // Manejar cualquier error que ocurra durante la llamada a la API
    });
};


export const handleClaimGananciaConsuelo = async (data, t) => {
  const username = localStorage.getItem("user");
  const requerimiento = { usuario: username, ganancia_apuestas_consuelo: Number(data)};

if(Number(data)>=10){
  // Enviar la transacción firmada al servidor utilizando Axios
  axios.post(`${linkApp}/claim_ganancia`, requerimiento)
    .then(res => {
      // Mostrar la respuesta en una alerta emergente
      alert(`Respuesta del servidor: ${JSON.stringify(res.data.body)}`);
    })
    .catch(error => {
      //console.log("error DE post axios", error);
      // Manejar cualquier error que ocurra durante la llamada a la API
    });
  }else{
    alert(`${t('wallet.retiroMinimoBHRT')}`);
  }
};





































































































































































































































































































































// const handleDepositarVista = () => {
//     if (!seleccionDepositarVisible) {
//       // Si el select no es visible, lo hacemos visible
//       setSeleccionDepositarVisible(true);
//       setSeleccionRetirarVisible(false);
//     } else {
//       // Si el select ya es visible, ejecutamos la función handleDepositarSaldo
//       setSeleccionDepositarVisible(false);
//       // handleDepositarSaldo(seleccion);
//     }
//   };


// const handleRetirarVista = () => {
//     if (!seleccionRetirarVisible) {
//       // Si el select no es visible, lo hacemos visible
//       setSeleccionDepositarVisible(false);
//       setSeleccionRetirarVisible(true);
//     } else {
//       // Si el select ya es visible, ejecutamos la función handleDepositarSaldo
//      setSeleccionRetirarVisible(false);
//       // handleDepositarSaldo(seleccion);
//     }
//   };


// const handleDepositarSaldo = () => {
//   manejarDeposito(seleccion);
// };


// const manejarDeposito = async (seleccion) => {

//   console.log("SALDO SEL",seleccion)
// let memo=`Add Balance BHR EQ`
// let toUsername="equino"
// let username=localStorage.getItem("user");
// let amount="" + Number(seleccion).toFixed(3)
// //let amount="1.000" 


// if(saldo<=15){

// const total_prox = Number(seleccion) + Number(saldo);
// console.log("TOTOTAL",total_prox)
// const limiteMaximo = 15;

// if (total_prox > limiteMaximo) {
//   amount = limiteMaximo - saldo;
//   amount = "" + amount.toFixed(3)
// }
// if (window.hive_keychain) {
//   const keychain = window.hive_keychain;
//   keychain.requestSendToken(username, toUsername, amount, memo, 'SWAP.HIVE', (response) => {
//     console.log("RES KeyC",response);
//     alert(response.message)
//     axios.post(`${linkApp}/deposit_wallet`, response) 
//       .then(res => {
//         //console.log("axio response",response)
       
//      })
//       .catch(error => {
//         //console.log("error DE post axio",error)
//         // manejar cualquier error que ocurra durante la llamada a la API
//       });
//   });
// } else {
//   alert('You do not have hive keychain installed');
// }
// }else{
//   alert('alcanzaste el saldo maximo permitido en wallets', 5)
// }


// };






// const handleRetiro = async (data, amounte) => {
//   const username = localStorage.getItem("user");
//   const consulta = { usuario: username, balance_drops: Number(data), status_claim: "create" };


//   // Enviar la transacción firmada al servidor utilizando Axios
//   axios.post(`${linkApp}/claim_drop`, consulta)
//     .then(res => {
//       // Mostrar la respuesta en una alerta emergente
//       alert(`Respuesta del servidor: ${JSON.stringify(res.data.body)}`);
//     })
//     .catch(error => {
//       //console.log("error DE post axios", error);
//       // Manejar cualquier error que ocurra durante la llamada a la API
//     });
// };

// const handleRetiroSaldo = async (data, amounte) => {
//   const username = localStorage.getItem("user");
//   const consulta = { usuario: username, balance: Number(data)};


// if(Number(data) > 0.5){  

// if (statusRetiro !== "pending" && statusRetiro !== "whitdrawing"){
//   // Enviar la transacción firmada al servidor utilizando Axios
//   axios.post(`${linkApp}/claim_balance`, consulta)
//     .then(res => {
//       // Mostrar la respuesta en una alerta emergente
//       alert(`Respuesta del servidor: ${JSON.stringify(res.data.body)}`);
//     })
//     .catch(error => {
//       //console.log("error DE post axios", error);
//       // Manejar cualquier error que ocurra durante la llamada a la API
//     });

// }else{ alert("You must wait for the withdrawal to be fully processed") }
// }else{alert("The minimum withdrawal allowed is 0.500 Swap.Hive")};


// }





// const handleClaimGanancia = async (data) => {
//   const username = localStorage.getItem("user");
//   const requerimiento = { usuario: username, ganancia_apuestas: Number(data)};

//   // Enviar la transacción firmada al servidor utilizando Axios
//   axios.post(`${linkApp}/claim_ganancia`, requerimiento)
//     .then(res => {
//       // Mostrar la respuesta en una alerta emergente
//       alert(`Respuesta del servidor: ${JSON.stringify(res.data.body)}`);
//     })
//     .catch(error => {
//       //console.log("error DE post axios", error);
//       // Manejar cualquier error que ocurra durante la llamada a la API
//     });
// };


// const handleClaimGananciaConsuelo = async (data) => {
//   const username = localStorage.getItem("user");
//   const requerimiento = { usuario: username, ganancia_apuestas_consuelo: Number(data)};

// if(Number(data)>=10){
//   // Enviar la transacción firmada al servidor utilizando Axios
//   axios.post(`${linkApp}/claim_ganancia`, requerimiento)
//     .then(res => {
//       // Mostrar la respuesta en una alerta emergente
//       alert(`Respuesta del servidor: ${JSON.stringify(res.data.body)}`);
//     })
//     .catch(error => {
//       //console.log("error DE post axios", error);
//       // Manejar cualquier error que ocurra durante la llamada a la API
//     });
//   }else{
//     alert(`${t('wallet.retiroMinimoBHRT')}`);
//   }
// };
