import React, { useState } from 'react';
import BalanceAndProfit from './BalanceAndProfit.jsx'; // Importar el nuevo contenedor
import ProfitsContainer from './ProfitsContainer.jsx';
import MinadoContainer from './MinadoContainer.jsx';

const WalletSections = ({ 
  t, 
  walletData, 
  saldo, 
  ganancia, 
  gananciaConsuelo, 
  gananciaConsueloBHR, 
  logos, 
  logosBHR,
  tickets,
  statusRetiro,
  hivelogo,
  setSeleccion,
  setSeleccionRetirarVisible,
  setSeleccionDepositarVisible, 
  seleccionRetirarVisible,
  seleccionDepositarVisible,
  
  handleDepositarSaldo,
  handleDepositarVista,
  handleRetirarVista,
  manejarDeposito,
  handleRetiro,
  handleRetiroSaldo,
  handleClaimGanancia,
  handleClaimGananciaConsuelo,


}) => {
  const [activeSection, setActiveSection] = useState('balance'); // Sección activa inicial

  const renderSection = () => {
    switch (activeSection) {
      case 'balance':
        return (
    <BalanceAndProfit 
  t={t}
  tickets={tickets}
  statusRetiro={statusRetiro}
  saldo={saldo}
  ganancia={ganancia}
  setSeleccion={setSeleccion} // Implementa la lógica para manejar la selección
  setSeleccionRetirarVisible={setSeleccionRetirarVisible}
  setSeleccionDepositarVisible={setSeleccionDepositarVisible}
  seleccionRetirarVisible={seleccionRetirarVisible}
  seleccionDepositarVisible={seleccionDepositarVisible}
  handleDepositarSaldo={handleDepositarSaldo}
  handleDepositarVista={handleDepositarVista}
  handleRetirarVista={handleRetirarVista}
  manejarDeposito={manejarDeposito}
  handleRetiro={handleRetiro}
  handleRetiroSaldo={handleRetiroSaldo}
  handleClaimGanancia={handleClaimGanancia}
  handleClaimGananciaConsuelo={handleClaimGananciaConsuelo}
/>

        );
      case 'profits':
        return (
          <ProfitsContainer 
            t={t}
            gananciaConsuelo={gananciaConsuelo} 
            gananciaConsueloBHR={gananciaConsueloBHR} 
            logos={logos} 
            logosBHR={logosBHR}
          />
        );
      case 'minado':
        return (
          <MinadoContainer 
            t={t} 
            walletData={walletData} 
            logos={logos} 
            handleRetiro={handleRetiro}
          />
        );
      default:
        return null;
    }
  };

 return (
    <div>
      <div className="button-wallets">
        {/* Conditional rendering of buttons */}
        {activeSection !== 'balance' && (
          <button onClick={() => setActiveSection('balance')}>Balance</button>
        )}
        {activeSection !== 'profits' && (
          <button onClick={() => setActiveSection('profits')}>Profits</button>
        )}
        {activeSection !== 'minado' && (
          <button onClick={() => setActiveSection('minado')}>Mining</button>
        )}
      </div>
      {renderSection()}
    </div>
  );
};

export default WalletSections;
