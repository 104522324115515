import React, { useState } from 'react';
import GananciaConsuelo from './GananciaConsuelo.jsx';
import ProfitBHR from './ProfitBHR.jsx';

const ProfitsContainer = ({ 
  t, 
  gananciaConsuelo, 
  gananciaConsueloBHR, 
  logos, 
  logosBHR 
}) => {
  const [isOpenInf, setIsOpenInf] = useState(false);
  const [isOpenModalBHRClaim, setIsOpenModalBHRClaim] = useState(false);

  const openModalInf = (ganancia) => {
    setIsOpenInf(true);
    // Puedes almacenar la ganancia si es necesario
  };

  const closeModalInf = () => setIsOpenInf(false);
  
  const openModalBHRClaim = () => setIsOpenModalBHRClaim(true);
  
  const closeModalBHRClaim = () => setIsOpenModalBHRClaim(false);

  return (
    <td>
      <GananciaConsuelo 
        t={t} 
        gananciaConsuelo={gananciaConsuelo} 
        logos={logos} 
        openModalInf={openModalInf} 
        isOpenInf={isOpenInf} 
        closeModalInf={closeModalInf} 
        setIsOpenInf={setIsOpenInf} 
      />
      <ProfitBHR 
        gananciaConsueloBHR={gananciaConsueloBHR} 
        logosBHR={logosBHR} 
        isOpenModalBHRClaim={isOpenModalBHRClaim} 
        openModalBHRClaim={openModalBHRClaim} 
        closeModalBHRClaim={closeModalBHRClaim} 
      />
    </td>
  );
};

export default ProfitsContainer;
