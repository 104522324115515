// // TorneoPremios.jsx
// import React, { useState } from 'react';
// import"./torneoPremios.css"

// export const TorneoPremios = ({ torneoData }) => {
//   const [mostrarPremios, setMostrarPremios] = useState(false);

//   const calcularPremiosFinal = () => {
//     const poolTotal1 = torneoData.pool_total_1 + torneoData.premio_1;
//     const poolTotal2 = torneoData.pool_total_2 + torneoData.premio_2;
//     const poolTotal3 = torneoData.pool_total_3 + torneoData.premio_3;

//     return {
//       primerLugar: {
//         [torneoData.symbol_1]: (poolTotal1 * 0.5).toFixed(2),
//         [torneoData.symbol_2]: (poolTotal2 * 0.5).toFixed(2),
//         [torneoData.symbol_3]: (poolTotal3 * 0.5).toFixed(2),
//         licencia: "Legendaria"
//       },
//       segundoLugar: {
//         [torneoData.symbol_1]: (poolTotal1 * 0.3).toFixed(2),
//         [torneoData.symbol_2]: (poolTotal2 * 0.3).toFixed(2),
//         [torneoData.symbol_3]: (poolTotal3 * 0.3).toFixed(2),
//         licencia: "Épica"
//       },
//       tercerLugar: {
//         [torneoData.symbol_1]: (poolTotal1 * 0.2).toFixed(2),
//         [torneoData.symbol_2]: (poolTotal2 * 0.2).toFixed(2),
//         [torneoData.symbol_3]: (poolTotal3 * 0.2).toFixed(2),
//         licencia: "Rara"
//       }
//     };
//   };

//   const premiosFinal = calcularPremiosFinal();

//   return (
//     <div className="torneo-premios">
//       <button onClick={() => setMostrarPremios(!mostrarPremios)}>
//         {mostrarPremios ? 'Ocultar Premios' : 'Mostrar Premios'}
//       </button>
//       {mostrarPremios && (
//         <div className="premios-info">
//           <h4>Premios del Torneo</h4>
//           <p>Fase 1: {torneoData.Premio_Fase_1} cofres especiales para cada participante</p>
//           <p>Fase 2: {torneoData.Premio_Fase_2} cofres especiales para cada participante</p>
//           {torneoData.tipo === '3Fases' ? (
//             <p>Carrera Final (Fase 3): {torneoData.Premio_Fase_3}</p>
//           ) : (
//             <>
//               <p>Fase 3: {torneoData.Premio_Fase_3}</p>
//               <p>Carrera Final (Fase 4): {torneoData.Premio_Fase_4}</p>
//             </>
//           )}
//           <h5>Premios de la Carrera Final:</h5>
//           <ul>
//             <li>1er Lugar: 
//               {Object.entries(premiosFinal.primerLugar).map(([key, value]) => 
//                 key !== 'licencia' ? <span key={key}>{value} {key}, </span> : null
//               )}
//               <p>Licencia de Cría Legendaria</p>
//               <p>+10 Puntos (Mejorar Random Cria)</p>
//             </li>
//             <li>2do Lugar: 
//               {Object.entries(premiosFinal.segundoLugar).map(([key, value]) => 
//                 key !== 'licencia' ? <span key={key}>{value} {key}, </span> : null
//               )}
//                <p>Licencia de Cría Mitica</p>
//               <p>+7 Puntos (Mejorar Random Cria)</p>
//             </li>
//             <li>3er Lugar: 
//               {Object.entries(premiosFinal.tercerLugar).map(([key, value]) => 
//                 key !== 'licencia' ? <span key={key}>{value} {key}, </span> : null
//               )}
//               <p>Licencia de Cría Epica</p>
//               <p>+5 Puntos (Mejorar Random Cria)</p>
//             </li>
//             <li>4to Lugar: Licencia de Cría Spectral</li>
//             <li>5to Lugar: Licencia de Cría Spectral</li>
//             <li>6to Lugar: Licencia de Cría Rara</li>
//             <li>7mo Lugar: Licencia de Cría Rara</li>
//             <li>8vo Lugar: Licencia de Cría Poco Comun</li>
//           </ul>
//         </div>
//       )}
//     </div>
//   );
// };



// TorneoPremios.jsx
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import "./torneoPremios.css"

export const TorneoPremios = ({ torneoData }) => {
  const [mostrarPremios, setMostrarPremios] = useState(false);
  const { t } = useTranslation();

 


    const calcularPremiosFinal = () => {
    const poolTotal1 = torneoData.pool_total_1 + torneoData.premio_1;
    const poolTotal2 = torneoData.pool_total_2 + torneoData.premio_2;
    const poolTotal3 = torneoData.pool_total_3 + torneoData.premio_3;

    return {
      primerLugar: {
        [torneoData.symbol_1]: (poolTotal1 * 0.5).toFixed(2),
        [torneoData.symbol_2]: (poolTotal2 * 0.5).toFixed(2),
        [torneoData.symbol_3]: (poolTotal3 * 0.5).toFixed(2),
        licencia: "Legendaria"
      },
      segundoLugar: {
        [torneoData.symbol_1]: (poolTotal1 * 0.3).toFixed(2),
        [torneoData.symbol_2]: (poolTotal2 * 0.3).toFixed(2),
        [torneoData.symbol_3]: (poolTotal3 * 0.3).toFixed(2),
        licencia: "Épica"
      },
      tercerLugar: {
        [torneoData.symbol_1]: (poolTotal1 * 0.2).toFixed(2),
        [torneoData.symbol_2]: (poolTotal2 * 0.2).toFixed(2),
        [torneoData.symbol_3]: (poolTotal3 * 0.2).toFixed(2),
        licencia: "Rara"
      }
    };
  };

  const premiosFinal = calcularPremiosFinal();

  return (
    <div className="torneo-premios">
      <button onClick={() => setMostrarPremios(!mostrarPremios)}>
        {mostrarPremios ? t('OcultarPremios') : t('MostrarPremios')}
      </button>
      {mostrarPremios && (
        <div className="premios-info">
          <h4>{t('PremiosDelTorneo')}</h4>
          <p>{t('Fase1Premios', { cantidad: torneoData.Premio_Fase_1 })}</p>
          <p>{t('Fase2Premios', { cantidad: torneoData.Premio_Fase_2 })}</p>
          {torneoData.tipo === '3Fases' ? (
            <p>{t('CarreraFinalFase3', { premio: torneoData.Premio_Fase_3 })}</p>
          ) : (
            <>
              <p>{t('Fase3', { premio: torneoData.Premio_Fase_3 })}</p>
              <p>{t('CarreraFinalFase4', { premio: torneoData.Premio_Fase_4 })}</p>
            </>
          )}
          <h5>{t('PremiosCarreraFinal')}</h5>
          <ul>
            <li>{t('PrimerLugar')}:
              {Object.entries(premiosFinal.primerLugar).map(([key, value]) => 
                key !== 'licencia' ? <span key={key}>{value} {key}, </span> : null
              )}
              <p>{t('LicenciaCriaLegendaria')}</p>
              <p>10 {t('Puntos')}{t('MejoraRandom')}</p>
            </li>
            <li>{t('SegundoLugar')}:
              {Object.entries(premiosFinal.segundoLugar).map(([key, value]) => 
                key !== 'licencia' ? <span key={key}>{value} {key}, </span> : null
              )}
              <p>{t('LicenciaCriaMitica')}</p>
              <p>7 {t('Puntos')}{t('MejoraRandom')}</p>
            </li>
            <li>{t('TercerLugar')}:
              {Object.entries(premiosFinal.tercerLugar).map(([key, value]) => 
                key !== 'licencia' ? <span key={key}>{value} {key}, </span> : null
              )}
              <p>{t('LicenciaCriaEpica')}</p>
              <p>5 {t('Puntos')}{t('MejoraRandom')}</p>
            </li>
            <li>{t('CuartoLugar')}: {t('LicenciaCriaSpectral')}</li>
            <li>{t('QuintoLugar')}: {t('LicenciaCriaSpectral')}</li>
            <li>{t('SextoLugar')}: {t('LicenciaCriaRara')}</li>
            <li>{t('SeptimoLugar')}: {t('LicenciaCriaRara')}</li>
            <li>{t('OctavoLugar')}: {t('LicenciaCriaPocoComun')}</li>
          </ul>
        </div>
      )}
    </div>
  );
};
