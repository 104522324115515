// src/components/JornadaSearch.jsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import './../css/JornadaSearch.css';

const JornadaSearch = ({ searchJornada, handleSearchChange, handleSearchJornada, onSearchClick }) => {
  const { t } = useTranslation();

  return (
    <div className="jornada-search">
      <p>{t('toneo.Search')}</p>
      <div className="search-input-container">
        <input
          type="text"
          placeholder={t('toneo.Search')}
          value={searchJornada}
          onChange={handleSearchChange}
          onKeyDown={handleSearchJornada}
          className="search-input"
        />
        <button onClick={onSearchClick} className="search-button">
          →
        </button>
      </div>
    </div>
  );
};

export default JornadaSearch;
