import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalWalletBHR from './../modals/modalWalletProfitsBHR.jsx';

const ProfitBHR = ({ gananciaConsueloBHR, logosBHR, isOpenModalBHRClaim, openModalBHRClaim, closeModalBHRClaim }) => {
  const { t } = useTranslation();

  return (
    <td>
      <span>
        {t('wallet.profit')}<br/>
        <h5>{gananciaConsueloBHR}<img src={logosBHR} alt="Token" className="coin" /></h5>
      </span>
      <button onClick={() => openModalBHRClaim(gananciaConsueloBHR)}>
        <span>{t('wallet.claim')}</span>
      </button>
      {isOpenModalBHRClaim && (  
        <ModalWalletBHR 
          isOpen={isOpenModalBHRClaim} 
          closeModal={closeModalBHRClaim}   
          propsi={gananciaConsueloBHR}  
          onClose={() => closeModalBHRClaim()}
        >
          <button className="modal-close" onClick={closeModalBHRClaim}>
            X
          </button>
        </ModalWalletBHR>
      )}
    </td>
  );
};

export default ProfitBHR;
