// TorneoInfo.jsx
// import React from 'react';
// import { TorneoPremios } from './torneoInfo/torneoPremios.jsx';
// import { useTranslation } from 'react-i18next';


// export const TorneoInfo = ({ torneoData }) => (

//   <div className="torneo-info">
//     <h3>{t(racein.ModalInfo.Torneo.Racing_Tournament_info)}</h3>
//     <p>Estado: {torneoData.status}</p>

    
//     <TorneoPremios torneoData={torneoData} />
    
//     {/* Puedes agregar más información relevante aquí */}
//   </div>
// );


//  const { t } = useTranslation();



// TorneoInfo.jsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TorneoPremios } from './torneoInfo/torneoPremios.jsx';

export const TorneoInfo = ({ torneoData }) => {
  const { t } = useTranslation();

  return (
    <div className="torneo-info">
      <h3>{t('racein.ModalInfo.Torneo.Racing_Tournament_info')}</h3>
       <p>{t('Estado')}: {torneoData.status === 'abierto' ? 'Open' : torneoData.status}</p>
      
      <TorneoPremios torneoData={torneoData} />
      
      {/* Puedes agregar más información relevante aquí */}
    </div>
  );
};
