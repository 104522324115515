import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './../css/FaseGanadores.css';

const GrupoGanadores = ({ ganadores, habilitadores, numeroCarrera, onNext, onPrev }) => {
  const [mostrarContenido, setMostrarContenido] = useState(false);
  const { t } = useTranslation();

  const toggleContenido = () => {
    setMostrarContenido(!mostrarContenido);
  };

  return (
    <div className="grupo-ganadores">
      <div className="grupo-header">
        <button onClick={onPrev} className="arrow-button">◀️</button>
        <h1>{t('Carrera')} {numeroCarrera}</h1>
        <button onClick={toggleContenido} className="toggle-visibility-btn">
          {mostrarContenido ? 
            <div className="ojo-cerrado">
              <div className="pestana pestana1"></div>
              <div className="pestana pestana2"></div>
              <div className="pestana pestana3"></div>
              <div className="pestana pestana4"></div>
            </div> : "👁️"}
        </button>
        <button onClick={onNext} className="arrow-button">▶️</button>
      </div>
      {mostrarContenido && (
        <div className="grupo-ganadores-card">
          {ganadores.map((ganador, index) => (
            <Link to={`/equine/${ganador}`} key={index} className="equine-link">
              <div className="equine-card">
                <span>{t('EquineID')}: {ganador}</span>
                <span>{t('Habilitador')}: {habilitadores[ganador] || t('Desconocido')}</span>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export function FaseGanadores({ fase1, fase2, fase3, fase4, habilitadores }) {
  const [showFase1, setShowFase1] = useState(false);
  const [showFase2, setShowFase2] = useState(false);
  const [showFase3, setShowFase3] = useState(false);
  const [showFase4, setShowFase4] = useState(false);
  const [currentCarreraIndex, setCurrentCarreraIndex] = useState({1: 0, 2: 0, 3: 0, 4: 0});
  const { t } = useTranslation();

  const onNext = (faseNum) => {
    const fase = [fase1, fase2, fase3, fase4][faseNum - 1];
    const grupos = Math.ceil(fase.length / 8);
    setCurrentCarreraIndex(prev => ({
      ...prev,
      [faseNum]: (prev[faseNum] + 1) % grupos
    }));
  };

  const onPrev = (faseNum) => {
    const fase = [fase1, fase2, fase3, fase4][faseNum - 1];
    const grupos = Math.ceil(fase.length / 8);
    setCurrentCarreraIndex(prev => ({
      ...prev,
      [faseNum]: (prev[faseNum] - 1 + grupos) % grupos
    }));
  };

 const renderFase = (fase, showFase, setShowFase, faseNum, totalFases) => {
  if (fase.length === 0) return null;

  const grupos = [];
  for (let i = 0; i < fase.length; i += 8) {
    grupos.push(fase.slice(i, i + 8));
  }

  return (
    <div>
      <button onClick={() => setShowFase(!showFase)}>
        {showFase ? 
          (faseNum === totalFases ? t('OcultarUltimaCarrera') : `${t('OcultarFase')} ${faseNum + 1}`) : 
          (faseNum === totalFases ? t('MostrarUltimaCarrera') : `${t('MostrarFase')} ${faseNum + 1}`)}
      </button>

      {showFase &&
        grupos.map((grupo, index) => (
          index === currentCarreraIndex[faseNum] && (
            <GrupoGanadores
              key={index + 1}
              ganadores={grupo}
              habilitadores={habilitadores}
              numeroCarrera={index + 1}
              onNext={() => onNext(faseNum)}
              onPrev={() => onPrev(faseNum)}
            />
          )
        ))}
    </div>
  );
};


  return (
    <div className="fases-ganadores">
      <h3>{t('GanadoresPorFase')}</h3>
      {renderFase(fase1, showFase1, setShowFase1, 1 ,2)}
      {renderFase(fase2, showFase2, setShowFase2, 2, 2)}
      {renderFase(fase3, showFase3, setShowFase3, 3, 2)}
      {renderFase(fase4, showFase4, setShowFase4, 4, 2)}
    </div>
  );
}
