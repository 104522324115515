import React, { useEffect, useState } from 'react';
import axios from 'axios';
import logos from'../../../../../../../assets/logoblockhorseracing/coinBHRT2.png';
import logosBHR from'../../../../../../../assets/logoblockhorseracing/logoBlock.png';

import { useTranslation } from 'react-i18next';
import { linkApp } from './../../../../../../generals/configuracion/variablesPublicas.jsx';
import { obtenerDatosWallet } from './../../../../../../generals/servicios/wallet/getSaldosW.jsx';

import WalletSections from './modulos/WalletSections'; // 
import { fetchWalletData } from './Data/walletData'; // Importar la función
import { closeModalInf, closeModalBHRClaim, openModalInf, openModalBHRClaim } from './handlers/modalHandlers'; // Importar las funciones

import {
  handleDepositarVista,
  handleRetirarVista,
  manejarDeposito,
  handleRetiro,
  handleRetiroSaldo,
  handleClaimGanancia,
  handleClaimGananciaConsuelo
} from './handlers/WalletOperations.jsx';




const FetchWallet = () => {
  const [walletData, setWalletData] = useState(null);
  const [saldo, setSaldo] = useState(null);
   const [tickets, setTickets] = useState(null);
   const [ganancia, setGanancia] = useState(null);
   const [gananciaConsuelo, setGananciaConsuelo] = useState(null);
   const [gananciaConsueloBHR, setGananciaConsueloBHR] = useState(null);
   const [seleccion, setSeleccion] = useState('0.5'); // Definimos el estado para el valor seleccionado
   const [seleccionDepositarVisible, setSeleccionDepositarVisible] = useState(false);
   const [seleccionRetirarVisible, setSeleccionRetirarVisible] = useState(false);
   const [statusRetiro, setStatusRetiro] = useState(false);
   const [isOpenInf, setIsOpenInf] = useState(false);
   const [isOpenModalBHRClaim, setIsOpenModalBHRClaim] = useState(false);
   const { t } = useTranslation();

   
   const closeModalInf = () => setIsOpenInf(false);
    const closeModalBHRClaim  = () => setIsOpenModalBHRClaim(false);
  const openModalInf = (id) => { setIsOpenInf(true);  }
 const openModalBHRClaim = (id) => { 
  setIsOpenModalBHRClaim(true); }

   const handleDepositarSaldo = () => {
    manejarDeposito(seleccion, saldo);
  };






 useEffect(() => {
    const user = localStorage.getItem("user");
    
    // Simulación de retraso de medio segundo
    const delay = 500;

    // Realizar la solicitud después del retraso
    const timerId = setTimeout(() => {
      fetchWalletData(user, setWalletData, setSaldo, setGanancia, setGananciaConsuelo, setGananciaConsueloBHR, setTickets, setStatusRetiro);
    }, delay);

    return () => clearTimeout(timerId); // Limpiar el timeout si el componente se desmonta
  }, []);








  return (
    <div>
      {walletData !== null ? (
        <div className="wallet">
          {/* Contenedor de Secciones */}
         <WalletSections 
  t={t}
  walletData={walletData}
  saldo={saldo}
  ganancia={ganancia}
  gananciaConsuelo={gananciaConsuelo}
  gananciaConsueloBHR={gananciaConsueloBHR}
  logos={logos}
  logosBHR={logosBHR}
  tickets={tickets}
  setSeleccion={setSeleccion}
  setSeleccionRetirarVisible={setSeleccionRetirarVisible}
  setSeleccionDepositarVisible={setSeleccionDepositarVisible} // O ajusta según tu lógica
  statusRetiro={statusRetiro}
  seleccionDepositarVisible={seleccionDepositarVisible}
  seleccionRetirarVisible={seleccionRetirarVisible}
  handleDepositarSaldo={handleDepositarSaldo}
  handleDepositarVista={handleDepositarVista}
  handleRetirarVista={handleRetirarVista}
  manejarDeposito={manejarDeposito}
  handleRetiro={handleRetiro}
  handleRetiroSaldo={handleRetiroSaldo}
  handleClaimGanancia={handleClaimGanancia}
  handleClaimGananciaConsuelo={handleClaimGananciaConsuelo}
/>

        </div>
      ) : (
        <p><span>&nbsp; {t('wallet.cargando')}&nbsp;</span></p>
      )}
    </div>
  );
};

export default FetchWallet;






















































































//   return (
//     <div>
//       {walletData !== null ? (
//         <div className="wallet">

//            <ProfitsContainer 
//             t={t}
//             gananciaConsuelo={gananciaConsuelo} 
//             gananciaConsueloBHR={gananciaConsueloBHR} 
//             logos={logos} 
//             logosBHR={logosBHR}
//           />
      



//           <MinadoContainer 
//             t={t} 
//             walletData={walletData} 
//             logos={logos} 
//             handleRetiro={() => handleRetiro()} 
//           />

//    <BalanceOperations 
//           t={t}
//           tickets={tickets}
//           statusRetiro={statusRetiro}
//           saldo={saldo}
//           hivelogo={hivelogo}
//           seleccionDepositarVisible={seleccionDepositarVisible}
//           setSeleccion={setSeleccion}
//           handleDepositarSaldo={handleDepositarSaldo}
//           seleccionRetirarVisible={seleccionRetirarVisible}
//           handleRetiroSaldo={handleRetiroSaldo}
//           handleDepositarVista={handleDepositarVista}
//           handleRetirarVista={handleRetirarVista}
//         />        
        

        

//     <Ganancia 
//           t={t}
//           ganancia={ganancia}
//           hivelogo={hivelogo}
//           handleClaimGanancia={handleClaimGanancia}
//         />
       
                
//         </div>
//       ) : (
//         <p><span>&nbsp; {t('wallet.cargando')}&nbsp;</span></p>

//       )}


//     </div>
//   );
// };
