import React, { useState } from 'react';
import Minado from './Minado.jsx';

const MinadoContainer = ({ t, walletData, logos, handleRetiro }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(prev => !prev);
  };

  return (
    <div>
    
        <Minado 
          t={t}
          walletData={walletData}
          logos={logos}
          handleRetiro={handleRetiro}
        />
    </div>
  );
};

export default MinadoContainer;
