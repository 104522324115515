// import React, { useState, useEffect } from 'react';
// import { linkApp } from './../../../../../generals/configuracion/variablesPublicas.jsx';


// import axios from 'axios';
// import { useTranslation } from 'react-i18next';

// import JornadaSearch from './componentes/modules/JornadaSearch.jsx';



// import {TorneoInfo} from './componentes/modules/TorneoInfo.jsx';
// import {Registrados} from './componentes/modules/Registrados';
// import { FaseGanadores } from './componentes/modules/FaseGanadores';

// const ModalResultadosTorneo = ({ isOpenPremiosJ, closeModalPremiosJ, recentRaces }) => {
//   const [torneoData, setTorneoData] = useState(null);
//   const [errorMessage, setErrorMessage] = useState('');
//   const [searchJornada, setSearchJornada] = useState('');
//   const { t } = useTranslation();


//   const onSearchClick = () => {
//     if (searchJornada) {
//       fetchtorneoData(searchJornada);
//     }
//   };

//   const checkConsistentJornadas = () => {
//     if (recentRaces.length === 0) return true;
//     const firstJornada = recentRaces[0].torneo;
//     const allSame = recentRaces.every(race => race.torneo === firstJornada);

//     if (!allSame) {
//       setErrorMessage('Error: Inconsistent Races Days. Reload the page and try again..');
//       return false;
//     }

//     setSearchJornada(firstJornada); // Establece el número de jornada en el buscador
//     return true;
//   };

//   const fetchtorneoData = async (jornada) => {
//     try {
//       const response = await axios.get(`${linkApp}/torneos_i?id_Torneo=${jornada}`);
//       console.log("Respuesta desde Jornada app data ", response.data.body.data);
//       setTorneoData(response.data.body.data); // Almacena los datos de la jornada
//       setErrorMessage(''); // Limpia los errores previos si la solicitud fue exitosa
//     } catch (error) {
//       setErrorMessage("The information couldn't be retrieved. Please try again");
//       setTorneoData(null); // Limpia los datos si hay un error
//     }
//   };

//   useEffect(() => {
//     if (isOpenPremiosJ) {
//       const isConsistent = checkConsistentJornadas();
//       if (isConsistent && searchJornada) {
//         fetchtorneoData(searchJornada);
//       }
//     }
//   }, [isOpenPremiosJ, recentRaces]);

//   const handleSearchChange = (e) => {
//     setSearchJornada(e.target.value);
//   };

//   const handleSearchJornada = (e) => {
//     if (e.key === 'Enter' && searchJornada) {
//       fetchtorneoData(searchJornada);
//     }
//   };


//   return (
//     <div className={`modalPremioJ-overlay ${isOpenPremiosJ ? 'is-open' : ''}`}>
//       <div className="modalPremioJ-content">
//         {/* ... (código existente) */}
        
//         <JornadaSearch 
//           searchJornada={searchJornada}
//           handleSearchChange={handleSearchChange}
//           handleSearchJornada={handleSearchJornada}
//           onSearchClick={onSearchClick}
//         />
   
//         {torneoData && (
//           <>
//             <TorneoInfo torneoData={torneoData} />
//             <FaseGanadores 
//               fase1={torneoData.Ganadores_Fase_1}
//               fase2={torneoData.Ganadores_Fase_2}
//               fase3={torneoData.Ganadores_Fase_3}
//               fase4={torneoData.Ganadores_Fase_4}
//             />
//             <Registrados registrados={torneoData.Registrados} />
//           </>
//         )}
      
//         {errorMessage && <div className="error-message">{errorMessage}</div>}
//       </div>
//     </div>
//   );
// };

// export default ModalResultadosTorneo;




















































import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { linkApp } from './../../../../../generals/configuracion/variablesPublicas.jsx';

import styles from './componentes/css/ModalTorneosResultados.module.css';

import JornadaSearch from './componentes/modules/JornadaSearch.jsx';
import { TorneoInfo } from './componentes/modules/TorneoInfo.jsx';
import { Registrados } from './componentes/modules/Registrados';
import { FaseGanadores } from './componentes/modules/FaseGanadores';

const ModalResultadosTorneo = ({ isOpenPremiosJ, closeModalPremiosJ, recentRaces }) => {
  const [habilitadores, setHabilitadores] = useState({});
  const [torneoData, setTorneoData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchJornada, setSearchJornada] = useState('');
  const [showRegistrados, setShowRegistrados] = useState(false);
  const { t } = useTranslation();

  const onSearchClick = () => {
    if (searchJornada) {
      fetchtorneoData(searchJornada);
    }
  };

  const checkConsistentJornadas = () => {
    if (recentRaces.length === 0) return true;
    const firstJornada = recentRaces[0].torneo;
    const allSame = recentRaces.every(race => race.torneo === firstJornada);

    if (!allSame) {
      setErrorMessage('Error: Inconsistent Races Days. Reload the page and try again..');
      return false;
    }

    setSearchJornada(firstJornada);
    return true;
  };


useEffect(() => {
  if (torneoData) {
    const habilitadoresMap = {};
    torneoData.Registrados.forEach(reg => {
      habilitadoresMap[reg.equineId] = reg.habilitador;
    });
    setHabilitadores(habilitadoresMap);
  }
}, [torneoData]);


  const fetchtorneoData = async (jornada) => {
    try {
      const response = await axios.get(`${linkApp}/torneos_i?id_Torneo=${jornada}`);
      console.log("Respuesta desde Jornada app data ", response.data.body.data);
      setTorneoData(response.data.body.data);
      setErrorMessage('');
    } catch (error) {
      setErrorMessage("The information couldn't be retrieved. Please try again");
      setTorneoData(null);
    }
  };

  useEffect(() => {
    if (isOpenPremiosJ) {
      const isConsistent = checkConsistentJornadas();
      if (isConsistent && searchJornada) {
        fetchtorneoData(searchJornada);
      }
    }
  }, [isOpenPremiosJ, recentRaces]);

  const handleSearchChange = (e) => {
    setSearchJornada(e.target.value);
  };

  const handleSearchJornada = (e) => {
    if (e.key === 'Enter' && searchJornada) {
      fetchtorneoData(searchJornada);
    }
  };

  return (
    <div className={`modalPremioJ-overlay ${isOpenPremiosJ ? 'is-open' : ''}`}>
      <div className="modalPremioJ-content">
        <div className="modal-header">
          <button onClick={closeModalPremiosJ} className="close-button">
            X
          </button>
          <h2>{t('racein.ModalInfo.Torneo')} : {searchJornada}</h2>
          <a
            href="https://docs-en.blockhorseracing.com/additional-game/6-in-a-row"
            className="rules-button-premiosJ"
            target="_blank"
            rel="noopener noreferrer"
          >
            📑Rules
          </a>
        </div>

        <JornadaSearch 
          searchJornada={searchJornada}
          handleSearchChange={handleSearchChange}
          handleSearchJornada={handleSearchJornada}
          onSearchClick={onSearchClick}
        />
   
        {torneoData && (
          <>
            <TorneoInfo torneoData={torneoData} />
         <FaseGanadores 
  fase1={torneoData.Ganadores_Fase_1}
  fase2={torneoData.Ganadores_Fase_2}
  fase3={torneoData.Ganadores_Fase_3}
  fase4={torneoData.Ganadores_Fase_4}
  habilitadores={habilitadores}
/>
          

          </>
        )}
      
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>
    </div>
  );
};

export default ModalResultadosTorneo;

